@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

html, body {
  margin: 0;
  background-color: #fff;
  font-family: 'Lato', sans-serif;
}

.col-md-cutom {
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100% !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  display: flex;
}
.col-md-4-height {
  flex: 0 0 33.3333333%;
  max-width: 33.3333333%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
}
.hide {
  display: none;
}
.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
  bottom: 2rem !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}

.nav-link {
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-size: 15px !important;
  font-weight: 700 !important;
}
.form-control-custom {
  border: 0 !important;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.navbar-custom {
  background-color: #fff !important;
}
.nav-image {
  width: 124px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.blur {
  /*position: -webkit-sticky;
  position: sticky;*/
  z-index: 40;
  display: flex !important;
  flex-direction: row;
  width: 100%;
  background: hsla(0,0%,100%,.1);
  backdrop-filter: blur(15px);
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 0.75rem;
  justify-content: space-between;
  flex-wrap: wrap;
}
.main-container {
  max-width: 1900px;
  padding: 0 !important;
  margin: 0 !important;
}
.section-big {
  padding-top: 100px;
  padding-bottom: 100px;
}
.section {
  padding-top: 50px;
  padding-bottom: 50px;
}
.section-container {
  position: relative;
}
.section-img {
  width: 100%;
  visibility: hidden;
}
.section-img-icon {
  width: 100%;
}
.section-img-title {
  width: 100%;
  position: absolute;
}
.section-title {
  font-size: 59px;
  font-weight: 500;
  line-height: 75px;
}
.section-desc {
  width: 80%;
  font-size: 17px;
  font-weight: 400;
}
.section-align-desc {
  width: 100%;
  font-size: 17px;
  font-weight: 400;
}
.footer {
  background-color: #000;
  padding-top: 45px;
  padding-bottom: 45px;
}
.footer-text {
  max-width: 700px;
  font-size: 12px;
  color: #fff;
}
.footer-icons {
  color: #fff;
  width: auto;
  font-size: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.action_button {
	box-shadow: 3px 4px 0px 0px #000;
	background-color:#176598;
	border-radius:50px;
	border:1px solid #000;
	display:inline-block;
	cursor:pointer;
	color:#fff;
	font-size:15px;
  font-weight: 600;
	padding:20px 50px;
	text-decoration:none;
	/*text-shadow:0px 1px 0px #e1e2ed;*/
}
.action_button:hover {
	background-color:#1e83c6;
}
.action_button:active {
	position:relative;
	top:1px;
}
.partner-container {
	box-shadow: 3px 4px 0px 0px #000;
	background-color: transparent;
	border-radius:15px;
	border:1px solid #000;
	display:inline-block;
	cursor:pointer;
	color:#fff;
	font-size:15px;
  font-weight: 600;
	padding:20px 50px;
	text-decoration:none;
	/*text-shadow:0px 1px 0px #e1e2ed;*/
}
.col-break {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.contact-background{
  background-image: url(./img/contact_back.png);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
.props-background{
  background-image: url(./img/home_zc_unidad.png);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: auto 110%;
  animation: shrink 10s infinite alternate;
}
.props-container{
  background-image: url(./img/home_container_inv.png);
  background-size: cover;
  background-position: bottom left;
  background-repeat: no-repeat;
}
.home-background{
  background-image: url(./img/home_zc_depas.png);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: auto 110%;
  animation: shrink 10s infinite alternate;
}
.home-container{
  background-image: url(./img/home_container.png);
  background-size: cover;
  background-position: bottom right;
  background-repeat: no-repeat;
}
.home-title {
  font-size: 75px;
  font-weight: 500;
  line-height: 80px;
}
.search-bar {
  background-image: url(./img/cuadro.png);
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
  position: relative;
  bottom: -20px;
  max-width: 1000px;
  padding: 20px;
}
.search-form {
  background-color: #fff;
  padding: 40px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.props-bar {
  background-image: url(./img/cuadro.png);
  background-size: contain;
  background-position: bottom 0px right 0px;
  background-repeat: no-repeat;
  position: relative;
  bottom: -20px;
  max-width: 1000px;
  padding: 20px;
}
.btn-general {
  background-color: #000;
  border-radius: 0 !important;
  color: #fff;
  border: 0 !important;
  padding: 15px 30px;
}
.partners-container {
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
  position: relative;
  bottom: -20px;
  max-width: 1000px;
  padding: 20px;
}
.us-background{
  background-image: url(./img/back_b.png);
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
}
.card-icon {
  height: 100px;
}
.card-title {
  font-size: 22px;
  font-weight: 600;
  color: #000;
}
.card-desc {
  font-size: 15px;
  font-weight: 400;
  color: #696969;
}
.card-style {
  background: url(./img/card_back.png);
  color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  padding: 20px 25px;
  text-align: center;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.card-props {
  width: 100%;
  max-width: 380px;
  position: relative;
  color: #fff;
  text-align: center;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.card-props-desc {
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 400;
  color: #000;
}
.card-background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.card-btn-props {
  position: absolute;
  right: 0;
  bottom: 0;
}
.card-element {
  position: relative;
  top: 2px;
}
.card-props-container {
  padding-top: 20px;
  padding-bottom: 20px;
}
.icon-small {
  width: 18px;
}
.height-custom {
  height: 10px;
}
.contact-form {
  background-color: #fff;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.properties-title {
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
  font-weight: bold;
}
.properties-subtitle {
  font-size: 15px;
  font-weight: 400;
  color: #696969;
}
.properties-price {
  font-size: 30px;
  font-weight: bold;
  color: #000;
}
.properties-items {
  position: relative;
  top: 1px;
  font-size: 12px;
  font-weight: 400;
  color: #000;
}
.properties-items-icon {
  font-size: 20px !important;
}
.properties-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.properties-card {
  position: relative;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.title-section {
  font-size: 35px;
  font-weight: bold;
  text-transform: uppercase;
}
.property-carousel {
  width: 100%;
  height: calc(100vw/2.5);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 110% auto;
  animation: shrink2 10s infinite alternate;
}
.collection-btn {
  position: absolute;
  border-radius: 50%;
  background-color: #fff;
  right: 10px;
  bottom: 10px;
  z-index: 99;
  cursor: pointer;
  padding: 15px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.realstate-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.contact-form-detail {
  border: 1px solid #c1c1c1;
  padding: 20px 15px;
  border-radius: 20px;
  text-align: center;
}
.contact-form-title {
  text-align: center;
  font-size: 17px;
  font-weight: 800;
  color: #646363;
}
.profile-testimonials {
  width: 50px;
  border-radius: 50%;
}
.testimonial-container {
  padding: 20px;
  text-align: left;
  color: #000;
}
.testimonial-title {
  font-weight: bolder; 
  font-size: 15px;
}
.testimonial-desc {
  font-size: 13px;
}
.gallery-style {
  width: 100%;
}

@keyframes shrink {
  0% {
    background-size: auto 110%;
  }
  100% {
    background-size: auto 100%;
  }
}

@keyframes shrink2 {
  0% {
    background-size: 110% auto;
  }
  100% {
    background-size: 100% auto;
  }
}

@media (max-width: 460px) {
  .col-md-4-height-mobile {
    margin-bottom: 25px;
  }
  .home-title, .section-title {
    font-size: 45px;
    line-height: 50px;
    text-align: center;
  }
  .home-container, .props-container{
    background: none;
  }
  .home-background, .props-background {
    background: none;
  }
  .search-form {
    padding: 15px;
  }
  .text-right {
    text-align: center;
  }
  .property-carousel {
    height: calc(100vw/1.5);
  }
}

@media (max-width: 768px) {
  .home-title, .section-title {
    text-align: center;
  }
  .home-container, .props-container{
    background: none;
  }
  .home-background, .props-background {
    background: none;
  }
  .text-right {
    text-align: center;
  }
  .no-padding {
    padding: 0 !important;
  }
}