  .header-des{
    background-color: #043f51!important;
    font-size: 15px;
  }
  .footer-font-tienes-desa{
    font-size: 45px;
    color: #d3d3d3;
    font-weight: 700;
    text-align: right;
    padding: 15px;
    width: 100%;
    background-color: #043f51;
  }
  .section-footer-desa{
    font-family: 'FilsonProRegular', sans-serif !important;
    font-size: 15px;
    color: #fff;
    height: 250px;
    position: static;
    background-color: #043f51;
    text-align: center;
    vertical-align: middle;
  }
  .desarrollo-text{
    font-size:65px ;
    font-weight: 800;
  }
  .desarrollo-descrip{
    font-size: 60px;
    line-height: 65px;
    color: #fff;
    font-weight: 600;
  }
  .desarrollo-descrip-tipo{
    font-size: 35px;
    line-height: 40px;
    color: #fff;
  }
  .desarrollo-descrip-costo{
    font-size: 25px;
    color: #ccb884;
  }
  .desarrollo-descrip-bg{
    position: absolute;
    bottom: -25px;
    background-color: #fff;
    color: #000;
    width: calc(100% - 50px);
    font-size: 15px;
    border-radius: 25px;
    padding: 15px 35px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .desarrollo-desc-compl {
    background-size: cover;
    background-position: center;
    border-radius: 35px;
  }
  .desarrollo-descrip-img{
    padding: 25px 25px 100px 25px !important;
  }
  .desarrollo-descrip-line{
    border-right: 3px solid #043f51;
    border-left: 3px solid #043f51;
  }
  .desarrollo-text-line {
    font-size: 13px;
    font-weight: 300;
  }
  .button-desa{
    border-radius: 35px;
    background-color: #2e2d2d;
    color: #fff;
    text-align: center;
    width: 100%;
    padding: 13px 20px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
  }
  /* Estilo para la galería de fotos */
.photo-gallery {
  display: flex;
  flex-wrap: wrap;
}

/* Estilo para las imágenes */
.photo {
  width: 20%;
  padding: 10px;
  box-sizing: border-box;
}

/* Estilo para "N fotos más" */
.more-photos {
  width: 20%;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
}
.detalle-desa{
  color: #807f7f;
  font-size: 20px;
}
.detalle-localidad{
  color: #000;
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
}
.detalle-costo{
  color: #ef5743;
  font-size: 45px;
  font-weight: 700;

}
.detalle-exteriores{
color: #000;
font-size: 35px;

}
.detalle-exterior{
  color:#707070;
  font-size: 15px;
}
.flecha-arriba{
  font-size: 25px;
  font-weight: 600;
  color: #043f51;
}
.caracteristicas-zona{
  font-size: 18px;
  color: #043f51;
}
.detalle-prom{
  background-color: #e5ebed;
  padding: 25px 30px;
}
.detalle-prom-text{
  color: #043f51;
  font-size: 20px;
  padding: 25px;
}
.elipse-style {
  width: 8px;
  margin-right: 5px;
}
.filter-container {
  background-color: #E5E5E5;
  border-radius: 15px;
  padding: 25px 25px
}
.avatar-icon {
  width: 45px;
}
.contact-info {
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
}
.input-label {
  padding: 15px 0;
  height: 36px;
}
.input-style {
  background-color: transparent !important;
  padding: 5px !important;
  border-bottom-color: #000 !important;
  border-radius: 0 !important;
}
.submit-btn {
  background-color: #fff !important;
  color: #043F51 !important;
  border-color: #043F51 !important;
}
.filter-title {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}
.sum-control {
  background-color: #fff !important;
  padding: 5px !important;
  color: #000 !important;
  width: 35px;
  height: 35px;
  margin-bottom: 5px;
}
.simi-title {
  font-size: 40px;
  color: #000;
  font-weight: 800;
}
.simi-return {
  font-size: 25px;
  color: #043F51;
  font-weight: 400;
}
.prev-img {
  width: 100%;
  margin-bottom: 10px;
}
.prev-text {
  font-size: 12px;
  padding: 20px;
}
.prev-title {
  font-size: 15px;
  font-weight: 600;
}
.prev-btn {
  color: #ccb884;
  font-weight: 700;
  margin-top: 10px;
}
.p-banner {
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 500px;
}
.p-search-container {
  padding-top: 250px;
  text-align: center;
}
.p-search {
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;
  width: 800px;
}
.p-title {
  font-size: 55px;
  font-weight: 700;
  text-align: center;
  color: #fff;
}
.p-container {
  background-color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
}
.p-body-title {
  font-size: 40px;
  line-height: 45px;
  font-weight: 700;
  text-align: left;
  padding-bottom: 15px;
}
.p-body-desc {
  font-size: 1s8px;
  font-weight: 400;
  text-align: left;
  padding-bottom: 25px;
}
.carousel-style {
  width: 100%;
}
.nav-link-tabs {
  color: #000 !important;
}

@media (max-width: 460px) {
  .detalle-localidad {
    font-size: 30px;
    line-height: 40px;
  }
  .detalle-desa {
    font-size: 18px;
  }
  .detalle-costo {
    font-size: 35px;
}
}